.product-radio {
  --color: #c71c8e;

  display: flex;
  gap: 10px;
  padding-left: 50px;
  margin-top: 1.1rem;
  border-color: var(--color) !important;
  color: var(--color) !important;

  .ant-radio-checked .ant-radio-inner {
    border-color: var(--color) !important;
  }

  .ant-radio-input {
    color: var(--color) !important;
  }

  .ant-radio-inner {
    border-color: #d4d0d3;
    color: var(--color) !important;
  }

  .ant-radio-inner:after {
    background-color: var(--color) !important;
  }
}

.custom-auto-compleat-new {
  .ant-select-selector {
    border-radius: 13px !important;
    height: 44px !important;

    input {
      height: 38px !important;
      background: url("../../../../assets/images/search-icon.png") no-repeat right center !important;
      background-size: 20px 20px !important;
      padding-right: 25px !important;
      border-radius: 0px !important;
      padding: 0px !important;
      padding-left: 0px !important;
      border: none !important;
      font-size: 16px !important;
    }

    .ant-select-selection-placeholder {
      line-height: 37px;
    }
  }
}

.loan-common-button {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  padding: 20px;
}