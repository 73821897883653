.step-button{
    background: #ccc;
    border: #ccc;
    color: #000;
    width: 100%;
    font-size: 14px;
    padding: 10px 9px;
    height: auto;
    border-radius: 30px;
    outline: none !important;
    &:hover{
        background: #ccc;
        border: #ccc;
    }
    &:focus{
        background: #ccc;
        border: #ccc;
    }
}

.form-box{
    padding: 20px 0px;
}

.custom-tab{
    --bgColor: #ffd5f1;
    --color: #273896 ;

    margin-top: 30px;
    .ant-tabs-tab:hover{
        color: var(--color) !important;
    }
    .ant-tabs-nav{
        color: var(--color);
        background: var(--bgColor);
        padding: 0px 4px;
        border-radius: 30px;
        &::before{
            display: none;
        }
        .ant-tabs-nav-wrap{
            .ant-tabs-nav-list{
                display: block;
                width: 100%;
                padding: 6px 0px;
                .ant-tabs-tab{
                    width: 50%;
                    margin: 0 !important;
                    padding: 8px 0px;
                    .ant-tabs-tab-btn{
                        width: 100%;
                        text-align: center;
                        color:#000;
                    }
                }
                .ant-tabs-ink-bar{
                    display: none;
                }
                .ant-tabs-tab.ant-tabs-tab-active{
                    background: #fff;
                    color: #000 !important;
                    border-radius: 30px;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
                }
            }
        }
    }
}

.search-box{
    width: 100%;
    box-shadow: 1px 1px 4px #eaeaea;
    border: solid 1px #f1f1f1;
    border-radius: 30px;
    .ant-input-group-wrapper{
        // box-shadow: 0px 4px 4px rgb(0 0 0 / 9%);
    }
    input{
        background-color: #fff !important;
        padding: 10px;
        border-color: #fff !important;
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
    }
    input[type="text"]{
        border-radius: 0px;
    }
    .ant-input-group-addon{
        border-bottom-right-radius: 30px;
        border-top-right-radius: 30px;
        button{
            height: 44px;
            background: none;
            border-color: #fff;
            background: #fff;
            border-bottom-right-radius: 30px !important;
            border-top-right-radius: 30px !important;
        }
    }
}

.payout-input{
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
    border-radius: 30px;
    padding: 10px;
    border: solid 1px #f1f1f1;
}

.custom-input{
    border-radius: 30px;
    padding: 8px;
    border: solid 1px #f1f1f1;
}

.search-result{
    .selected-item{
        margin: 23px 0px;
        border: solid 1px #ccc;
        width: 100%;
        padding: 8px;
        border-radius: 8px;
        .ant-space-item{
            line-height: normal;
            .ant-typography{
                margin-bottom: 0px;
                line-height: normal;
            }
        }
    }
}

.continue-emp-btn{
    width: 100%;
    text-align: right;
}

.custom-checkbox{
    margin-bottom: 10px;
    .ant-radio-button-wrapper{
        margin-right: 10px;
        border-radius: 30px;
        cursor: pointer;
        &::before{
            display: none;
        }
    }
}
.upload {
    width: 100%;
    min-height: 100px;
    padding-top: 7%;
    /* padding:1 10px; */
    background: rgba(39, 56, 150, 0.13);
    border: 1px dashed #273896;
    border-radius: 12px;
    margin: 0 20px 20px 0;
    color: #273896;
}
.image {
    border-radius: 50%;
}