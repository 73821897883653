.label-verified {
    position: absolute;
    margin-top: 30px;
    margin-right: 8px;
    font-size: 13px !important;
    color: #20A74E;
    padding: 0px 2px;
    align-items: center;
    display: flex;

    img {
        margin-right: 3px;
        width: 15px !important;
        height: 15px !important;
    }
}

.label-verficiation-pending {
    position: absolute;
    margin-top: 30px;
    margin-right: 8px;
    right: 30px;
    font-size: 11px !important;
    color: #E12121;
    border: 1.5px solid #E12121;
    padding: 0px 2px;
    border-radius: 5px;
}