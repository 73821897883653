.borrower-screen {
    padding: 15px;
}

.page-img {
    width: 30%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.img-width {
    width: 100%;
}

.terms-conditions {
    overflow: auto;
    height: 150px;

}

.mobile-view {
    height: 60vh;
}

.borrower-card {
    width: 25%;
    min-width: 300px;
    height: 61%;
    min-height: 460px;
    border-radius: 10px;
    box-shadow: 5px 5px 20px #babbbf9a;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 20%);
}

.borrower-details {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 13%;
    transform: translate(-8%, -50%);
}

.confirm-text {
    color: #20A74E;
    font-size: 20px;
    font-weight: 300;
}

.expired-text {
    color: #C71C8E;
    margin-top: 10px;
    font-size: 20px;
    font-family: sans-serif !important;
}