.dashboard-broker-body{
    margin-left: 10%;
    margin-right: 10%;
    height: 91vh;
    align-items: center;
    display: flex;
}

.dashboard-broker-menus {
    margin: 40px 0px;
    display: table;
    width: 100%;
    border: 1px solid #ddd;
    padding: 35px; 
}

.desktop-layout .content-wrapper{
    background-color: #fff;
}
.divDivder{
    border-right: 1px solid #ddd;
    text-align: center;
}

.divDivderLast{
    text-align: center;
}

.col-p-text{
    margin-top: 10px;
}

.col-p-textLast{
    margin-top: 10px;
}

.dashboard-borker-button{
    border      :   none;
    background  :   none
}
@media only screen and (max-width: 600px) {
    .divDivder {
    padding: 20px;
    border-bottom: none;
    border-right: none;
    }

    .col-p-text{
        border-bottom: 1px solid #ddd;
        padding-bottom: 25px;
        color: #000;
    }
    .col-p-textLast{
        padding-bottom: 25px;
        color: #000;
    }
  }

