.lead-detail-box1{
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    // height: 682px;
    .heading{
        display: flex;
        border-bottom: solid 1px #ccc;
        padding: 6px 15px;
        align-items: baseline;
        justify-content: space-between;
        min-height: 46px;
        align-items: center;
        h5{
            font-weight: 900;
            font-size: 17px;
            line-height: 22px;
            color: #000;
            margin-bottom: 0px;
        }
        button{
            font-size: 12px;
        }
    }
    .custom-row{
        justify-content: space-between;
        margin-bottom: 10px;
        .gap{
            gap: 4px !important;
        }
    }
    .title{
        color: #9f9f9f;
        font-size: 13px;
    }
    .lead-details{
        padding: 10px 15px;
        border-bottom: solid 1px #e9e9e9;
        .source{
            font-weight: 400;
            font-size: 12px;
            color: #6a6767;
        }
        h5{
            margin-bottom: 2px;
        }
    }
    .details-item{
        padding: 15px;
        textarea{
            width: 100%;
            border-radius: 8px;
        }
        .btn-space{
            display: flex;
            margin-top: 10px;
            justify-content: end;
        }
    }
}

.user-icon {
    --appPrimary: #273896;
    circle,
    path {
        stroke: var(--appPrimary);
    }
}