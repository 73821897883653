.menu-bar-box{
    display: none;
    float: left;
    margin-left: 0px;
    margin-top: 7px;
    svg{
        font-size: 26px;
        color: #2a3791;
    }
}
.navbar-box{
    .ant-drawer-content-wrapper{
        background: white;
        .ant-drawer-content{
            padding: 0px;
            background: none;
            .ant-drawer-wrapper-body{
                .ant-drawer-header{
                    display: none;
                }
                .ant-drawer-body{
                    padding: 0px;
                }
            }
        }
    }
}
@media only screen and (max-width: 1096px) {
    .menu-bar-box{
        display: inline-block;
    }
    .desktop-layout {
        background: rgb(248, 247, 251) !important;
    }
    .left-nav{
        display: none;
    }
    // .content-wrapper{
    //     width: 100%;
    //     flex: 100%;
    //     padding: 52px 15px !important;
    //     padding-top: 22px !important;
    //     max-width: 100%;
    //     display: block;
    //     margin-left: 0 !important;
    // }
    .setcol{
        margin-right: 0px !important;
        background: #e5e5e5;
        border-radius: 30px;
        padding: 4px 13px !important;
    }
    .lead-detail-box{
        height: auto !important;
    }
    .timeline{
        height: auto !important;
    }
    .lender-list-wrapper{
        .ant-col-12{
            width: 100%;
            display: block;

        }
    }
}

@media only screen and (max-width: 960px) {
    .connector-top-row{
        display: table;
        width: 100%;
        .left{
            width: 70%;
            float: left;
            .searchBox{
                width: 70%;
            }
        }
        .right{
            width: 30%;
            float: right;
        }
    }
}

@media only screen and (max-width: 680px) {
    .custome-devider-hozntl{
        display: none;
    }
    .connector-filter-btn{
        display: none;
    }
    .lead-filter-row{
        display: table;
        width: 100%;
        .left{
            float: left;
            width: 60%;
        }
        .right{
            float: right;
        }
    }
    .ant-tabs-content-top{
        overflow-x: scroll;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;
        .ant-tabs-tabpane{
            width: 100%;
        }
    }
    .ant-tabs-content-top::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
    .add-offer-wrapper{
        .ant-col-8{
            display: block;
            width: 100%;
            max-width: unset;
            flex: unset;
        }
        .ant-col-16{
            display: block;
            width: 100%;
            max-width: unset;
            flex: unset;
        }
    }
    .add-notice-wrapper{
        .ant-col-10{
            display: block;
            width: 100%;
            max-width: unset;
            flex: unset;
        }
        .ant-col-16{
            display: block;
            width: 100%;
            max-width: unset;
            flex: unset;
        }
    }
}

@media only screen and (max-width: 575px) {
    .permission-col{
        min-height: unset !important;
    }
}