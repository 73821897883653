.outer-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inner-form {
  margin-top: 18px;
  background-color: #fff;
  border-radius: 37px;
  font-family: "Montserrat", sans-serif;
  /* box-shadow: 1px 1px 9px 1px grey; */
  box-shadow: 1px 1px 11px 6px rgb(0 0 0 / 7%);
  height: "480";
  width: "500px"
}

.title1 {
  display: flex;
  justify-content: center;
}

.title11 {
  text-align: center;
}

.title111 {
  display: flex;
  justify-content: space-around;
  padding: 0px 1rem;
  margin-top: 1rem;
}

.back {
  display: flex;
  justify-content: space-around;
  padding: 0px 1rem;
  margin-top: 1rem;
  color: blue;
}