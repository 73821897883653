.maindiv{
    background-color:rgba(217, 217, 217, 0.3);
    border-radius:10px;
    height:120px;
    width: 100%;
    margin-left: 5px;
    box-sizing: border-box;
}

.maindivLeftIn{
    --bgColor:#273896;
    background: var(--bgColor);
    height  :   77px; 
    width   :   84px;
    border-radius: 10px;
    text-align: center;
    float: left;
    color: white;
    margin-top: 5px;
    margin-right: 5px;
    margin-left: 5px;
    // box-sizing: border-box;
}

.maindivLeftOut{
    --borderColor:#C71C8E;
    background: var(--borderColor);
    height  :   77px; 
    width   :   84px;
    border-radius: 10px;
    text-align: center;
    float: left;
    color: white;
    margin-top: 5px;
    margin-right: 5px;
    margin-left: 5px;
    // box-sizing: border-box;
}

.maindivRight{
    height:100% ;
    width: 100%;
    box-sizing: border-box;
}

.check{
   padding-top: 5px;
   margin-bottom: 5px;
   margin-top: 10px;
}

.pinkButtonCheckOut {
    --borderColor:#C71C8E;
    background: var(--borderColor);
    color: "white";
}

.blueButtonCheckIn{
    --bgColor:#273896;
    background: var(--bgColor);
    color: "white";
}

.content-box {
    margin-inline   : 25px;
    margin-top      : 10px;
    margin-bottom   : 20px;
}