.hide-header{
    .ant-modal-header {
        display: none;
    }
}
.offer-details {
    :root {
        --clr-border: #333;
        --AnimationLength: 0px;
    }
    .main-section {
        margin-top: 10px;
    }
    .slider-amt {
        margin-left: 15px;
        margin-right: 15px;
    }
    .req-tenure {
        & span {
            font-family: Avenir LT Std;
            font-size: 15px;
            font-weight: 350;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: left;
        }
    }
    .ant-slider-dot {
        display: none;
    }
    .tenure-select {
        border: 1px solid #cfc6c6 !important;
        height: 45px !important;
        & .ant-select-selector {
            align-items: flex-end !important;
        }
    }
    .ant-select-arrow {
        align-items: start;
    }
    .emival {
        margin-top: 15px;
        display: flex;
        justify-content: center;
        margin: 6px;
        & .emiValue {
            font-weight: 750;
            font-size: 22px;
            display: flex;
            & .emiValue-amt {
                font-weight: 750;
                font-size: 22px;
                align-self: center;
            }
            & .emiValue-roi {
                font-weight: 750;
                font-size: 15px;
                align-self: center;
            }
        }
        & .emiText {
            font-weight: 350;
            margin-left: 5px;
            margin-right: 5px;
            font-size: 15px;
            align-self: center;
        }
    }
    .btn-offer.ant-btn.ant-btn-primary {
        min-width: 550px !important;
        width: 550px !important;
        max-width: 540px !important;
        height: 55px;
    }
    .ant-modal-body {
        padding: 0px;
        padding-bottom: 10px;
    }
    .ant-slider-rail {
        height: 5px;
    }
    .ant-slider-track {
        height: 5px;
    }
    // .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    //     border: none;
    // }
    // .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    //     .ant-select-selector:focus-visible {
    //     border: none;
    // }
    // .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    //     border: none;
    // }
    .amt-breackdown {
        margin-top: 15px;
        background: #fafafa;
        padding: 15px;
        border-top: 1px solid #e7e7e7;
        border-bottom: 1px solid #e7e7e7;
        & .amt-breackdown-header {
            & span {
                font-weight: 750;
                font-size: 18px;
                font-family: Avenir LT Std;
                margin: 8px;
            }
        }
        & .amt-details {
            margin: 15px 0px 15px 0px;
            & .amt-detail-row {
                display: flex;
                justify-content: space-between;
                margin: 10px 8px;
                & span {
                    font-weight: 350;
                    font-size: 15px;
                    color: #000000;
                }
            }
        }
        & .line-dotet {
            margin: 0px 8px 0px 8px;
            font-size: 1.5em;
            padding: 0.5em;
            line-height: 1;
            // border: 1px solid #808080;
            background-position:
                0 0,
                0 0,
                100% 0,
                0 100%;
            background-size:
                3px 100%,
                100% 1px,
                3px 100%,
                100% 3px;
            background-repeat: no-repeat;

            // Slanted
            background-image:
                repeating-linear-gradient(
                    45deg,
                    var(--clr-border),
                    var(--clr-border) 0px,
                    transparent 0px,
                    transparent 0px
                ),
                // left
                repeating-linear-gradient(
                        100deg,
                        var(--clr-border),
                        var(--clr-border) 10px,
                        transparent 10px,
                        transparent 20px
                    ); // top
            // repeating-linear-gradient(225deg, var(--clr-border), var(--clr-border) 10px, transparent 10px, transparent 20px), // right
            // repeating-linear-gradient(315deg, var(--clr-border), var(--clr-border) 10px, transparent 10px, transparent 20px) // bottom
        }
        & .final-amt {
            margin: 0px 8px 0px 8px;
            display: flex;
            justify-content: space-between;
            font-weight: 350;
            font-size: 15px;
        }
    }
}
