@import './Variables.scss';

.d-flex {
  display: flex;
}

.gap-10 {
  gap: 10px;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.p-10 {
  padding: 10px;
}

.pi-30 {
  padding-inline: 30px;
}

.page-wrapper {
  padding: 20px;
}

.h-center {
  display: flex;
  justify-content: center;
}

.h-space-between {
  display: flex;
  justify-content: space-between;
}

.form-wrapper {
  padding: 20px;
  background-color: white;
  border-radius: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.has-error {
  .help-block {
    display: none;
  }
}

.alert,
.alert-danger {
  display: none;
}

