

.iframe-pdf{
    width: 100%;
    height: 350px;
    display: block;
}

.img-address{
    width: 100%;
    display: block;
    height: 350px;
}

.field-bg{
    padding: 8px 10px;
    background: #FFFFFF;
    box-shadow: 5px 5px 13px rgba(223, 224, 230, 0.5);
    border-radius: 12px;
    border: solid 1px #f7f7f7;
    margin-bottom: 10px;
    .ant-select-selector,
    .custom-input-phone,
    .custom-input-number,
    .custom-input,
    .custom-textarea{
        border-radius: 0px;
        padding: 0px !important;
        padding-left: 0px;
        border: none !important;
        font-size: 16px;        
      }
  }

 form:disabled {
   
}