.hide-selection .ant-table-selection-column {
    cursor: not-allowed;
    display: none !important;
}

.export-button {
    width: 100px;
    height: 45px;
    border-radius: 10px;
}

.payout-checkable {
    border-radius: 10px 10px 0px 0px;
    height: 30px;
    line-height: 2;
    font-size: 13px;
    &:hover,
    &:focus{
      color: rgba(0, 0, 0, 0.85) !important;
      background: transparent;
    }
}

.payout-checkable.ant-tag-checkable-checked {
    --bgColor: #273896;
    background: var(--bgColor);
    color:#fff;
    &:hover,
    &:focus{
      color: #fff !important;
    }
}

.hide-columns {
    display: none !important;
}