.prefrPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #F8F7FB;
}
.mainSection {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.1);
    padding: 30px;
    margin: 15px;
}
.imgSection {

}
.tncSection {
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-checkbox {
        margin: 2px;
    }
    .ant-checkbox-wrapper {
        margin: 2px;
    }
    span {
        margin: 2px;
    }
}

.offerSection {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .ant-checkbox {
        margin: 2px;
    }
    .ant-checkbox-wrapper {
        margin: 2px;
    }
    span {
        margin: 2px;
    }
}

.btnSection {
    padding: 10px;
    .ant-btn {
        width: 100px;
        height: 40px;
    }
}
.ant-checkbox-wrapper {
    display: flex;
    align-items: center;
}

a {
    text-decoration: auto !important;
}