.form-div{
  box-shadow:  1px 1px 9px 1px  grey;
  border-radius: 12px;
  margin: 12px;
  height: 75px;
}
.customInp {
  width: 312px;
  height: 30px;
  border: 3px solid #fff;
  padding: 0 1rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  margin-top: 2px;
}
.customInp:hover {
  border: 3px solid #C71C8E;
}
.signin-label{
  float: left;
  padding: 0 1rem;
  margin: 5px;
}
