@import './Mixins.scss';

// Colors

$ambiant-color: #042750; //Used for menu color
$primary-color: #273896;
$secondary-color: #fff;
$light-secondary-color: #6dade6;
$danger-color: #fe7c96;
$warning-color: #fed713;
$success-color: #52c41a;
$info-color: #287fb5;

$black: #0c0b0f;
$white: #ffffff;
$dark-gray: #787878;
$medium-gray: #a5a5a5;
$light-gray: #d9d9d9;
$header-height: 50px;

$title-font: 'Roboto', sans-serif;

$font-family: 'Roboto', sans-serif;
$font-color: #3c3b37;
$font-color-light: #555555;
$font-color-lighter: #dccedf;


$page-background: #fafcff;
$sidebar-background: $ambiant-color;
$sidebar-selected-item-background: darken($sidebar-background, 20%);
$sidebar-footer-background: darken($sidebar-background, 5%);
$sidebar-sub-menu-background: darken($sidebar-background, 5%);

$header-background: $sidebar-background;
$header-color: $secondary-color;

// We have multiple shadow levels to represent perspective
$shadow-0: 0 2px 2px rgba(0, 0, 0, 0.1);
$shadow-1: 2px 4px 6px rgba(0, 0, 0, 0.1);
* {
  transition: box-shadow 0.1s ease-in;
}

@import './Markdown.scss';
