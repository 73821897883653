.visiting-card-main {
  position: relative;
  justify-content: center;
  height: 265px;
  width: 600px;
  background: rgb(255, 255, 255);
  border-radius: 20px;
  left: 0px;
  top: 0px;
 // color: white;
 color: rgba(0,0,0,1);
  .bg {
   //  background: linear-gradient(180deg, rgb(43, 55, 145) 12%, rgb(147, 46, 139) 88%) !important;
   // background: linear-gradient(180deg, rgb(255,255,255) 12%, rgb(147, 46, 139) 88%) !important;
   background: white;
    border-radius: 20px;
    height: 100%;
  }
}

.visiting-card-outer {
  height: 100%;
  background-image: url("../../assets/images/visiting/visitingCardBackGround.png");
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Split the screen in half */
.split {
  height: 100%;
  position: relative;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-inline: 20px;

}

/* Control the left side */
.left {
  left: 0;
  width: 35%;
}

/* Control the right side */
.right {
  width: 65%;
  position: relative;
  right: 0;
  padding-inline: 5px;
  display: flex;
}

.visit-image {
  width: 100%;
}

.visit-divider {
  height: 4%;
  .divider {
    background-color: rgba(0,0,0,1);
    height: 1px;
  }
}

.visit-icon-display {
  --btnColor:rgba(39,56,150,1);
  background-color: var(--btnColor);
  border-radius: 5px;
  width: 28px;
  height: 130px;
  display: flex;
  flex-direction: column;
  margin-block: auto;
  justify-content: space-around;
  padding-block: 8px;
}

.visit-information {
  border-radius: 5px;
  height: 130px;
  display: flex;
  flex-direction: column;
  margin-block: auto;
  justify-content: space-around;
  padding-block: 8px;
  padding-left: 5px;
  p {
    margin: 0px !important;
  }
}

.visit-icon {
  color: white;
  margin-top: 15px;
}

.visit-mobile-icon {
  height: 20px;
  width: 20px;
  margin-left: 3px;
}

.visit-email-icon {
  height: 20px;
  width: 17px;
  margin-left: 5px;
}

.visit-network-icon {
  height: 20px;
  width: 15px;
  margin-left: 6px;
}

.visit-detail {
  text-align: left;
  margin-top: 8px;
  margin-left: 5px;
  padding-left: 6px;

}

.visit-share-butoon {
  --btnColor:#273896;
  background:var(--btnColor);
  display: flex;
  justify-content: center;
  gap: 10px;
  border: none;

  &:hover,
  &:focus{
    background:var(--btnColor);
    color:#fff;
  }
}

.web-share {
  color: white !important;
  background: #273896;
  border-radius: 6px;
  border: 1px solid #273896;
  width: 100px;
  height: 34px;
}

.visiting-name {
  --color: #273896;
  margin-bottom: 0px;
  text-align: left;
  font-size: 22px;
  color:var(--color);
}

