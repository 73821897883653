.disbursed-lenders-header {
  margin-bottom: 10px;

  .disbursed-heading {
    font-size: 12px;
  }
}

.selected-lender-row {
  align-items: center;

  .disbursed-row {
    h5 {
      font-size: 13px;
      font-weight: normal;
    }
  }
 }


.btn-add {
  border: none;
  background: none;
  padding: 0px;

  img {
    padding: 4px;
    margin-bottom: 10px;
    border: solid 1px #c71c8e;
    border-radius: 20px;
  }
}

.inline-label {
  .ant-row {
    display: block;

    .ant-col {
      width: 100%;

      label {
        display: block;
        text-align: left;
        height: auto;
      }
    }
  }
}

.disbursed-collapse {
  .ant-collapse-item {
    border-bottom: none;

    .ant-collapse-header {
      width: 100%;
      min-height: 44px;
      .ant-collapse-header-text {
        width: 100%;

        h4 {
          font-size: 14px;
          line-height: normal;
          margin-bottom: 0px;
        }

        h6 {
          font-size: 13px;
          margin-bottom: 0px;
        }

        .api-intergrated-text {
          --appSecondary: #8c2e8b;
          display: block;
          font-size: 14px;
          font-weight: 500;
          color: var(--appSecondary);
          line-height: 15px;
        }
      }
    }

    .ant-collapse-content-box {
      padding: 0px;
      padding-bottom: 100px;
      height: 350px !important;
      overflow-y: auto;
      .disbursal-item {
        margin-bottom: 8px;
        border-bottom: 1px solid lightgray;
      }
    }
    .ant-collapse-content-box::-webkit-scrollbar {
      width: 8px;
      background-color: #F5F5F5;
      display: block;
      height: 8px;
    }
    .ant-collapse-content-box::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
      background-color: lightgray;

    }
  }
}

.bg-title {
  padding-block: 10px;
  padding-inline: 16px;
  background: rgba(217, 217, 217, 0.2);
}

.inline-label {
  .ant-row .ant-col label {
    font-size: 10px !important;
  }
}


.mobile-view-icon-btn {
  &.ant-btn-primary{
    background-color: transparent !important; 
    border-color: transparent !important;
  }
}
.mobile-view-custome-devider-hozntl{
  .custome-devider-hozntl{
    //display: none;
    width: auto;
}
 }
@media only screen and (max-width: 1000px) {
  .disbursal-item {
    border-bottom: none !important;
  }
}

.view-Disbursal{
  margin-left: 50px;//25px;
  cursor: pointer;
  path{
    stroke: #273896 !important;
  }
}

.view-Disbursal1{
  margin-left: 70px;//25px;
  cursor: pointer;
  path{
    stroke: #273896 !important;
  }
}