.case-radio {
    --btnRoundRadio: #c71c8e;
    display: flex;
    gap: 10px; 
    border-color:var(--btnRoundRadio) !important;
    color: black !important;
    &-wrapper:hover .ant-radio,
    &:hover,
    &-focused {
      .ant-radio-inner {
        border-color: var(--btnRoundRadio) !important;
      }
    }
    
    .ant-radio-checked .ant-radio-inner {
      border-color: var(--btnRoundRadio) !important;   
    }
    .ant-radio-input {
      color: var(--btnRoundRadio) !important;   
    }
  
    .ant-radio-inner {
      border-color: #d4d0d3;
      color: var(--btnRoundRadio) !important;
    }
    .ant-radio-inner:after {
      background: var(--btnRoundRadio) !important;
      border: 1px solid var(--btnRoundRadio) !important;
  }
    
  }
 .case-btn{
    width:111px;
    margin-top: 20px;
    height: 40px;
    border-radius: 10px;
 } 