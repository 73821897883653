.lender-additional-details {
  --appSecondary: #8c2e8b;
  line-height: 15px;
  font-size: 10px;
  color : var(--appSecondary);
  circle
  {
    stroke: var(--appSecondary);
  }
  path {
    fill: var(--appSecondary);
  }
}
