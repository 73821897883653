.cust-main-title {
  display: flex ;
  justify-content: center;
  margin-top: 20px;
  span {
    font-size: 40px;
    font-weight: 600;
    color: #24223E;
    text-align: center;
    .span2 {
      color: #ED217C;
    }
  }
}

.cust-main-subtitle {
  display: flex ;
  justify-content: center;
  margin-bottom: 20px;
}

.cust-tab-main {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  .ant-segmented {
    background-color: transparent;
    .ant-segmented-group {
      padding: 3px;
      border: 1px solid #CECEEA;
      border-radius: 10px;
      background: none;
      .ant-segmented-item {
        padding-inline: 8px;
      }
      .ant-segmented-item-selected {
        background: linear-gradient(86.6deg, #0F467C 0%, #ED217C 100%);
        color: white;
        border-radius: 10px;
      }
    }
  }
}

.cust-items-title {
  display: flex;
  justify-content: center;
  .cust-items {
    display: flex;
    gap: 10px;
    border-bottom: 1px solid #D3D3D3;
    .cust-item {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      // color: #00000070;
      cursor: pointer;
      padding-bottom: 4px;
      .cust-item-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
      }
      &.cust-item-selected {
        padding-bottom: 0px;
        border-bottom: 4px solid #0F467C
      }
    }
  }
}

.cust-item-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
  text-align: center;
  padding-inline: 10px;
  svg {
    width: 100%;
    max-width: 1000px;
    @media (max-width: 768px) {
      height: calc(70vw);
    }
  }
}
