.review-main {
  display: grid;
  justify-content: center;
  margin-top: 40px;
  .name {
    text-align: center;
    font-size: 29px;
    font-weight: 700;
  }
  .founder-of {
    display: flex;
    color: #d12525;
    font-size: 18px;
    font-weight: 700;
    gap: 5px;
    align-items: center;
    justify-content: center;
    svg {
      height: 20px;
    }
  }
  .review-desc {
    max-width: 700px;
    font-size: 25px;
    text-align: center;
    margin-top: 20px;
    margin-inline: 10px;
  }
  .review-users {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .review-user {
      padding-top: 20px;
      padding-bottom: 5px;
      border-radius: 8px;
      &.selected {
        background: #ffb936;
      }
      svg {
        height: 90px;
        width: 90px;
      }
    }
  }
  .btn-Get-in-Touch {
    margin-top: 50px;
    margin-bottom: 20px;
    padding-inline: 30px;
    justify-self: center;
    width: fit-content;
    color: white;
    background: linear-gradient(86.6deg, #0f467c 0%, #ed217c 100%);
  }
}
