.footer {
  background-color: black;
  color: white;
  padding: 10px 10%;
  .row {
    margin-top: 50px;
    margin-bottom: 100px;
  }
  .footer-logo {
    .logo {
      width: 150px;
      margin-bottom: 10px;
    }
  }
  .footer-contact-col {
    justify-items: end;
  }
  .footer-contact {
    .ant-typography {
      color: #ffffff;
    }
  }
}

.footer-line {
  border: none;
  border-top: 1px solid white;
  margin: 20px auto;
}

.footer-copyright {
  text-align: center;
  margin: 10px 0;
  .copyright-text {
    color: #ffffff;
  }
}

@media (max-width: 768px) {
  .footer {
    .footer-logo {
      padding-left: 12px;
    }
    .row {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .footer-contact-col {
    display: none;
  }
}
