.button {
  background: #273896;
  border: none;
  border-radius: 10px;
  width: 345px;
  height: 45px;
  font-size: 17px;
  font-weight: 800;
  color: #fff;
}




