.lead-card {
  // width: 75%;
  display: flex;
  // height: 700px;
  justify-content: center;
  // padding: 40px !important;
  padding: 20px;
  padding-inline: 40px;
  // margin-right: 568px;
}

.lead-card-header {
  display: flex;
  justify-content: center;
}

.common-button {
  display: flex;
  justify-content: center;
  margin-block: 20px;
  gap: 10px;
}

.msg {
  display: flex;
  justify-content: center;
  margin-top: 19px;
}

.select-applicant-type {

  .ant-select:not(.ant-select-customize-input),
  .ant-select-selector {
    height: 40px;
  }
}

.disabled-view {
  position: absolute;
  inset: 0;
  background: #FFFFFF88;
  z-index: 9;
  max-width: 450px;
  cursor: no-drop;
}

.lead-loanType {
  height: 570px;
  overflow: auto;
}

.custome-radio {
  --appPrimary: #273896;
  svg {
    font-size: 34px;
    path,
    circle {
      stroke:var(--appPrimary);
    }
  }
  .stroke-fill {
    fill: var(--appPrimary);
    path {
      fill: var(--appPrimary);
    }
  }
}

// ::-webkit-scrollbar {
//   display: none;
// }