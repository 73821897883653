.red {
    color: red;
}
.green {
    background-color: #e5e5e5;
}
.table {
    width: 100%;
    margin-top: 40px;
}

.add-button {
    height: 45px;
    // width: 130px;
    width: 171px;
    border-radius: 10px;
    // float: right;
    svg {
        margin-right: 10px;
        font-size: 13px;
    }
}
// }

.btn-box {
    margin-bottom: 30px;
    .ant-radio-button-wrapper {
        margin-right: 10px;
        border-radius: 30px;
        cursor: pointer;
        &::before {
            display: none;
        }
    }
}

.action-button {
    button {
        border: none;
        line-height: 26px;
        svg {
            font-size: 11px !important;
        }
    }
    .blue {
        background: #273896;
    }

    .green {
        background: #20a74e;
    }

    .pink {
        background: #c71c8e;
    }
}
.maincard {
    border-radius: 30px;
    max-width: 556px;
    min-height: 451px;
    display: block;
    margin: 20px auto;
    padding: 10px;
}

.inputField {
    border: 1px solid #efefef;
    box-shadow: 1px 5px #efefef;
    border-radius: 5px;
    line-height: 40px;
}
thead[class*='ant-table-thead'] th {
    // opacity: 0.7 !important;
    font-size: smaller;
}
.ant-modal-content {
    border-radius: 30px;
    overflow: auto;
}
.submit {
    /* width: 261.94px; */
    width: 100%;
    height: 40px;
    background: #273896;
    border-radius: 10px;
    color: #fff;
    margin-left: 5%;
}

.cancel {
    box-sizing: border-box;
    /* width: 174.41px; */
    width: 100%;
    height: 40px;
    border: 1px solid #c71c8e;
    border-radius: 10px;
    margin-right: 20px;
    color: #c71c8e;
}
