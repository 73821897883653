.upload-document-modal{
    padding-bottom: 20px;
}
.deletePopup {
    max-width: 500px;
}

.custom-select{
    width: 100%;
}

.upload-btn-submit{
    width: 100%;
    margin-top: 20px;
    border-radius: 30px;
    height: 41px;
}

.upload-btns{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    button{
        margin: 0px 5px;
    }
}
.upload-wrapper{
    
    .ant-upload-select-text{
        width: 100%;
        height: 76px;
        margin: 14px 0px;
        svg{
            margin-right: 10px;
        }
        span{
            display: flex;
            width: 100%;
            height: 100%;
            text-align: center;
            align-items: center;
            justify-content: center;
            border: dotted 1px #ccc;
            cursor: pointer;
        }
    }
    .ant-upload-list{
        padding-bottom: 15px;
        .ant-upload-list-item{
            margin-bottom: 10px;
        }
    }
}

.download-btn{
    width: 100px;
    //margin: auto;
    margin-bottom: 15px;
    margin-left: 2px;
    display: flex;
    align-items: center;
    border-color:#fff !important;
    svg{
        margin-right: 10px;
    }
    &:hover,
    &:focus{
       color:#000;
    }
}

.custom-divider-hozntl {
    min-height: 25px;
    height: auto;
    color: black;
}