.feature-item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  margin-inline: 200px;

  .feature-item-text {
    display: grid;
    gap: 10px;
    flex: 1;
    .feature-item-title {
      font-size: 35px;
      font-weight: 700;
    }
    .feature-item-subtitle {
      font-size: 24px;
    }
    .feature-item-desc {
      font-size: 18px;
      font-weight: 300;
    }
  }

  .feature-item-image {
    position: relative;
    padding: 20px;
    flex: 1.1;
    .img-bg-shadow {
      position: absolute;
      width: 100%;
      height: 40%;
      left: 0;
      top: 0;
      right: 0;
      background: linear-gradient(
        201deg,
        rgba(64, 221, 255, 0.5) 20%,
        rgba(118, 18, 250, 0.5) 67%
      );
      filter: blur(70px);
      margin-top: 50px;
    }
    svg {
      position: relative;
      max-width: 100%;
      max-height: 400px;
    }
  }
  @media (max-width: 768px) {
    margin-inline: 20px;
    display: grid;
    gap: 10px;
    margin-top: 20px;
    .feature-item-text {
      .feature-item-title {
        font-size: 30px;
      }
      .feature-item-subtitle {
        font-size: 20px;
      }
      .feature-item-desc {
        font-size: 16px;
      }
    }
    .feature-item-image {
      padding: 0px;
      .img-bg-shadow {
        filter: blur(30px);
        margin-top: 0px;
      }
      svg {
        max-height: 250px;
      }
    }
  }
}
