.iframe-pdf-view {
    width: 100%;
    height: 350px;
    display: block;
}

.img-address-view {
    width: 100%;
    display: block;
    height: 350px;
}