.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  margin-top: 40px;

  .bpf:nth-child(even) {
    background: linear-gradient(180deg, #ffffff 69.67%, #e7edf2 100%);
  }

    .bpf:nth-child(odd) {
    background: linear-gradient(180deg, #ffffff 70.47%, #fef4f9 100%);
  }
}

.bpf {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  text-align: left;
  border: none;
}

.bpf-image {
  width: 100%;
  height: auto;
  border-radius: 10px 10px 0 0;
  padding: 50px;
  padding-top: 0px;
}

.bpf-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  color: rgba(15, 70, 124, 1);
}

.bpf-items {
  list-style: none;
  padding: 0;

  li {
    margin: 10px 0;
    font-size: 16px;
    color: rgba(15, 70, 124, 1);
    display: flex;
    align-items: center;

    .check-icon {
      color: #4caf50;
      margin-right: 10px;

      img {
        height: 20px;
      }
    }
  }
}

@media (max-width: 768px) {
  .cards-container {
      flex-direction: column;
      align-items: center;
      gap: 0;
      margin: 0;
      padding: 0;
      border-radius: unset;
    }

    .bpf {
      width: 100%;
      border-radius: unset;
      box-shadow: none;
      padding: 0;
      margin: 0;
      // align-items: center;
    }

    .bpf-image {
      order: 3;
      padding: 50px;
      padding-top: 20px;
      border-radius: unset;
    }

    .bpf-title {
      margin-top: 40px;
      padding: 20px 0px 0px 40px;
      font-size: 2.3em;
      // align-self: center;
    }
.bpf-items {
  padding: 0 40px;
}

}