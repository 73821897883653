.edit-additional-main-div {
    display: flex;
    justify-content: space-between;
}
.edit-additional-sub-div {
    padding: 10px;
    width: 50%;
}

.co-applicant-details{
    border: none;
    background: transparent;
    .ant-collapse-item{
        margin-bottom: 10px;
        border: 1px solid rgb(218,218,218);
        background: rgb(250,250,250);
    }
}