.BSA-output-list-item-div{
    border: 1px solid #00000030;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    .BSA-output-list-item-left {
        width: 40%;
        display: grid;
        gap: 10px;
    }
    .BSA-output-list-item-right {
        width: 60%;
        display: grid;
        gap: 10px;
    }
    .BSA-output-list-item {
        display: grid;
        .BSA-output-list-item-title {
            font-weight: 300;
        }
    }
}