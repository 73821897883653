.doc-list{
    display: table;
    width: 100%;
    padding-bottom: 30px;
    ul{
        // list-style: none;
        padding: 0px;
        margin-left: 5%;
        li{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: solid 1px #ededed;
            margin-bottom: 10px;
            padding-bottom: 10px;
            .doc-details{
                --fileIconColor:#273896;
                display: flex;
                align-items: center;
                svg{
                    height: 35px;
                    width: 35px;
                   // margin-left:-20px;
                    path{
                       stroke: var(--fileIconColor);
                    }
                    font-size: 32px;
                   //margin-right: 5px;
                }
                span{
                    width: 170px;
                    font-size: 13px;
                    text-overflow: ellipsis;
                    overflow: hidden; 
                    white-space: nowrap;
                    .type{
                        font-size: 12px;
                    }
                }
            }
            .doc-action{
               
                span.remove{
                    color: #E12121;
                    cursor: pointer;
                    svg{
                        margin-right: 8px;
                        position: relative;
                        top: 3px;
                    }
                }
            }
        }
        li:last-child{
            border-bottom: none;
        }
    }
}