.permission-col{
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    min-height: 640px;
}

.permission-heading{
    font-weight: 600 !important;
    font-size: 15px !important;
    color: #000000 !important;
    padding: 12px 10px;
    border-bottom: solid 1px #eee;
}

.permission-box{
    padding: 8px;
    .lender-list-box{
        height: 530px;
        overflow-y: auto;
        .ant-checkbox-wrapper{
            margin-left: 0px;
            display: flex;
        }
    }
}

.permission-btn{
    justify-content: right;
    padding-top: 40px;
    text-align: right;
    padding-right: 9px;
    button{
        height: 43px;
    }
}


.panel-heading{
    label{
        margin-right: 10px;
    }
}

.custom-collapse-permission{
    background-color: #fff;
    .ant-collapse-item{
        .ant-collapse-header{
            padding: 8px 0px;
            justify-content: space-between;
            .ant-collapse-expand-icon{
                order: 1;
            }
            .ant-collapse-header-text{
                order: 0;
            }
        }
    }
}