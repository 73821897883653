.edit-form {
    background-color: white;
    border-radius: 5px;
    //margin-left: -1.9em;
    position: fixed;
  //  padding: 20px;
    display: contents;
}

.edit-lead-card-new {
  padding: 10px;
  min-height: 1200px;
  max-height: 1400px;
}