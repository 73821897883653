.dashboard-menus {
  --appPrimary: #273896;
  --appSecondary: #8c2e8b;
  
 // margin: 40px 0px;
  display: table;
  width: 100%;
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    margin-top: 20px;
    li {
      background-color: white;
      width: 163px;
      height: 115px;
      margin-bottom: 10px;
      box-shadow: -5px 5px 10px rgb(223 224 230 / 20%),
        5px -5px 10px rgb(223 224 230 / 20%),
        -5px -5px 10px rgb(255 255 255 / 90%),
        5px 5px 13px rgb(246 246 246 / 90%),
        inset 1px 1px 2px rgb(255 255 255 / 30%),
        inset -1px -1px 2px rgb(223 224 230 / 50%);
      border-radius: 10px;
      float: left;
      margin-right: 20px;
      a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 10px;
        padding-top: 25px;
        position: relative;
        svg {
          font-size: 34px;
          path,
          circle {
            stroke:var(--appPrimary);
          }
        }
        .stroke-fill {
          fill: var(--appPrimary);
          path {
            fill: var(--appPrimary);
          }
        }
        #header-shape-gradient {
          --color-stop: #f12c06;
          --color-bot: #faed34;
        }
        span.count{
          position: absolute;
          right: -8px;
          top: -8px;
          color: white;
          background: var(--appSecondary);
          min-width: 32px;
          padding-inline: 4px;
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          text-align: center;
          border-radius: 30px;
          -webkit-text-fill-color: unset;
        }
        span {
          font-size: 20px;
          display: block;
          font-size: 14px;
        }
      }
    }
  }
}

.icon {
  margin-left: 10px;
  margin-bottom: 12px;
}
.text {
  margin-left: 10px;
}
@import 'shared/style/Variables.scss';
@import 'shared/style/Mixins.scss';

.custom-carousel{
    .slick-list{
        .slick-track{
          height:250px;
            .slick-slide{
                img{
                    width: 100%;
                }
            }
        }
    }
    .slick-dots{
        bottom: 12px;
        right: 0;
        left: auto;
        margin-right: 4%;
        li{
            width: auto !important;
            button{
                width: 10px;
                height: 10px;
                background: rgba(0, 0, 0, 0.35);
                @include border-radius(10px)
            }
        }
        li.slick-active{
            button{
                background: #C71C8E;
            }
        }
    }
}

.icon {
  margin-left: 10px;
  margin-bottom: 12px;
}
.text {
  margin-left: 10px;
}
