@import 'shared/style/Variables.scss';
@import 'shared/style/Mixins.scss';

.login {
  padding: 0;
  text-align: center;
  min-height: 100%;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background: #E5E5E5;
  .login-wrapper{
    min-width: 350px;
    max-width: 400px;
  }
  .logo{
    width: 122px;
    margin: auto;
    margin-bottom: 20px;
    img{
      width: 100%;
    }
  }
  .login-form-wrapper {
    width: 100%;
    @include box-shadow(0px 4px 22px rgba(0, 0, 0, 0.1));
    .form-wrapper{
      text-align: left;
    }
  }
  .ant-input-password{
    padding-right: 15px;
  }
  // input:-webkit-autofill,
  // input:-webkit-autofill:hover,
  // input:-webkit-autofill:focus,
  // input:-webkit-autofill:active {
  //     transition: background-color 500s ease-in-out 0s;
  //     -webkit-text-fill-color: rgb(0, 0, 0) !important;
  // }
  input, .ant-input-affix-wrapper > input.ant-input{
    border-radius: 15px;
    padding: 4px 11px;
  }
  .custom-input-phone .ant-input-wrapper .ant-input-group-addon .ant-select,
  .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 15px 0px  0px 15px;
  }
}

