.customer-content-box{
  background-color: rgba(248,247,241,1);
  width:100%;
  padding:20px 0
 }
 
 .customer-loan-content-box{
   background-color: rgba(255, 255, 255,1);
   width:100%;
   padding:20px 
  }

.left-content-box{
   color: rgba(253,253,253,1);
   height: 350px;
   padding: 0 20px;
   border-radius: 6px;
   margin: 0 auto;
 }
 
 .left-content-box1{
  color: rgba(253,253,253,1);
  height: 220px;
  padding: 0 20px;
  border-radius: 6px;
  margin: 0 auto;
}

 .left-text-div-content{
   align-items: center;
   justify-content: space-between;
   display: flex;
   border-radius: 6px;
   margin-top: 0px;
 }

.left-text-content{
   color:rgba(82,82,86,1);
   font-size: 14px;
 }
 
.left-text-content1{
   color:rgba(82,82,86,1);
   font-size: 16px;
 }
 
.right-text-content{
   color:rgba(0,0,0,1);
 
 }
 
.right-text-content1{
   color:rgba(0,0,0,1);
   background-color: #F4F5F7;
   border-radius:2px;
   padding: 4px,8px;
   font-size: 12px;
 
 }

 .ant-card-head-wrapper{
   inline-size: 150px;
   word-break: break-all;
   padding: 1px;
 }

 .col-border-bottom-3 {
   border-bottom: 3px solid rgb(235, 234, 238);
 }

 .details-icons {
   float: right;
   color: black;
   border: none;
 }

 .table-content-grid{
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-radius:6px;
  box-shadow: 0px 0px 23px 0px #4545501A
}

 .table-title-content{
    margin: 0;
    font-size: 14px;
    color: rgba(101,101,117,1);
    height:50px
 }

 .table-value-content{
    font-size: 14px;
    color: rgba(101,101,117,1);
    margin: 0;    
    height: 20px;
}

.table-title-content1{
  line-height: 13px;
  margin: 0;
  font-size: 14px;
  color: rgba(101,101,117,1);
  height:40px;
  border-bottom: 0.1px solid lightgrey;
}

.table-value-content1{
  white-space: nowrap;
  line-height: 24px;
  font-size: 14px;
  color: rgba(101,101,117,1);
  margin-top: 13.4px; 
  margin-bottom: 8px; 
  height: 38px;   
  border-bottom: 0.1px solid lightgrey;
}

 .table-text-content{
   font-size: 14px;
   color: rgba(101,101,117,1);
  border: none;
 }

 .table-content .ant-table-thead > tr > th{
   font-size: 14px;
   color: rgba(101,101,117,1);
   background-color: white;
   
 }

.p-content{
    margin-top: 5px;
    font-size: 20px;    
    color: rgba(82,82,86,1);   
}

.card-PQaction-content{
  height: 350px;
}

.card-action-content{
  height: 220px;
}

.ant-tooltip-inner{
  background-color: #1A3175;
}