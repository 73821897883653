.faq-panel {
  // .ant-collapse-header {
  //   background-color: white !important;
  // }

  .ant-collapse-item {
    border-radius: 10px !important;
    box-shadow: 0px 0px 11px 0px rgba(33, 33, 33, .2) !important;
    margin-bottom: 10px;
  }

  .ant-collapse-content {
    background-color: #E5E8F5 !important;
    border-bottom-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;

    .ant-collapse-content-box {
      //padding-bottom: 0px !important;
      padding: 15px 10px 8px !important;
    }
  }

  .ant-collapse-expand-icon {
    display: "flex" !important;
    justify-content: "end" !important;
    // margin-left: 100px !important;
  }
}

.content {
  min-width: 270px;
  max-width: 27%;//52%;
}

.about_name {
  margin-bottom: -1em;
}

.text {
  float: right;
  margin-top: -12px;
}

.about_btn {
  margin-top: 30px;
  width: 20%;//40%;
  margin-left: 60px;
}

.copy_btn {
  height: 45px;
  width: 150px !important;
  border-radius: 20px;

  svg {
    margin-right: 10px;
    font-size: 14px;
  }
}

.share_btn {
  height: 45px;
  width: 150px !important;
  border-radius: 20px;

  svg {
    margin-right: 10px;
    font-size: 14px;
  }
}

.btn-box {
  --bgColor:#273896;
  .help-tab-box {
    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #fff !important;
    }
    .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab-active {
      border-bottom-color: #fff;
      background: var(--bgColor);
      color:#fff;
      border-radius: 10px 9px 0px 0px !important;
    }
    .ant-tabs-tab {
      color: black !important;
      border-radius: 10px 9px 0px 0px !important;
    }
  }
}
