.balance-overview {
    margin-top: 20px;
    .balance-overview-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .balance-overview-items {
        margin-top: 20px;
        display: flex;
        gap: 20px;
        .balance-overview-item {
            border-radius: 5px;
            display: flex;
            align-items: center;
            border: 1px solid #A3A3A340;
            padding: 10px;
            gap: 10px;
            .boi-amount {
                display: grid;
                .boi-amount-title {
                    color: #0000008C;
                    font-size: 12px;
                }
            }
            svg{
                height: 60px;
            }
        }
        @media (max-width: 768px) {
            display: grid;
        }
    }
}

.chart {
    overflow: auto;
    }