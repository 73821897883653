.bg-shadow {
  position: absolute;
  margin-left: calc((100vw - 1200px) / 2);
  margin-top: 100px;
  width: 1200px;
  height: 200px;
  max-width: 100vw;
  background: linear-gradient(
    201deg,
    rgba(64, 221, 255, 0.5) 20%,
    rgba(118, 18, 250, 0.5) 67%
  );
  box-shadow: 107px 107px 107px;
  filter: blur(70px);
  @media (max-width: 768px) { 
    margin-left: 0px;
    height: 250px;
  }
}

.crmSoftwereTitle {
  text-align: center;
  span {
    font-size: 45px;
    font-weight: 700;
    line-height: 59.85px;
    letter-spacing: -0.02em;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    &.one {
      color: #0f467c;
    }
    &.flo {
      color: #ed217c;
    }
  }
}

.feature-image {
  display: flex;
  justify-content: center;
  margin-inline: 10px;
  svg {
    width: 100%;
    max-width: 1000px;
    @media (max-width: 768px) {
      height: calc(70vw);
    }
  }
}

.features-icons {
  margin: 0px 20px;
  display: table;
  justify-self: center;
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    margin-top: 10px;
    li {
      float: left;
      margin-right: 20px;
      .feature {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #00000070;
        cursor: pointer;
        .feature-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #ffffff80;
          border: none;
          border-radius: 12px;
          width: 50px;
          height: 50px;
          margin: 5px;
        }
        &.feature-selected {
          color: #000000;
          .feature-icon {
            width: 60px;
            height: 60px;
            margin: 0px;
            border: 0.98px solid #24223ecc;
            background: #24223ecc;
            svg {
              path {
                fill: white;
                &.iconCust360 {
                  stroke: white !important;
                  fill: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.features-mobile {
  background-color: white;
  border: 1px solid #e7ebf0;
  justify-self: center;
  padding: 25px 35px;
  border-radius: 10px;
  gap: 20px;
  display: grid;
  margin-inline: 10px;
  margin-bottom: 30px;
  .feature-mobile {
    display: flex;
    gap: 30px;
    align-items: center;
    svg {
      width: 25px;
    }
    .name-desc {
      display: grid;
      .name {
        font-weight: 400;
        color: #010101;
        font-size: 20px;
      }
      .desc {
        font-weight: 400;
        font-size: 12px;
        color: #333333;
      }
    }
  }
}
