.content-box {
    margin-inline: 25px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.modal-Add-extra {
    .ant-modal-body {
        padding-right: 0px !important;
    }
}

#basic {
    max-height: 65vh;
    overflow-y:hidden;
    height: 100%;
    overflow-x: hidden;
}

// .hide-selected-item {
//     .ant-select-selector {
//         .ant-select-selection-overflow {
//             // display: none;
//         }
//     }
// }

.badge-green {
    height: 15px;
    width: 15px;
    background-color: green;
    position: absolute;
    top: -3px;
    right: -2px;
    border-radius: inherit;
}

.assigned-lender-list {
    margin-top: 20px;

    .assigned-list {
        padding: 8px 10px;
        background: #FFFFFF;
        box-shadow: 5px 5px 13px rgba(223, 224, 230, 0.5);
        border-radius: 6px;
        border: solid 1px #f7f7f7;
        margin-bottom: 10px;
    }

    .ant-radio-group {
        width: 100%;
    }

    .ant-space-vertical {
        width: 100%;
    }

    .lender-checkbox {
        display: flex;
        align-items: flex-start;

        .ant-checkbox-inner {
            width: 21px;
            height: 21px;
            border-radius: 10px;

            &::after {
                left: 30.5%;
            }
        }

        .ant-checkbox {
            margin-bottom: 5px;
        }
    }

    .checkbox-text {
        display: block;
        font-size: 14px;
        font-weight: 500;
        color: #5c5b5b;
        line-height: 15px;
    }

    .checkbox-text.small {
        font-size: 10px;
    }

    .checkbox-text-div {
        display: flex;
        justify-content: space-between;
    }

    .api-intergrated-text {
        --appSecondary: #8c2e8b;
        display: block;
        font-size: 14px;
        font-weight: 500;
        color : var(--appSecondary);
        line-height: 15px;
        padding-left: 6px;
        img {
            margin-right: 5px;
        }
        circle {
            stroke: var(--appSecondary);
        }
        path {
            fill: var(--appSecondary);
        }
    }

    .ant-checkbox+span {
        width: 100%;
    }
}

.left-nav {
    header {
        .ant-row {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
    }
}

.btn-box {
    margin-bottom: 10px !important;

    .ant-radio-group {
        display: flex;
        overflow-x: auto;
        white-space: nowrap;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .ant-radio-group::-webkit-scrollbar {
        display: none;
    }

    .ant-radio-button-wrapper {
        margin-right: 10px;
        border-radius: 30px;
        margin-bottom: 10px;
        border-left-width: 1.02px;
        cursor: pointer;

        &::before {
            display: none;
        }
    }
}

.comment-box {
    margin-top: 30px;
    width: 100%;

    textarea {
        height: 100px;
    }
}

.next-btn {
    width: 100%;
    margin-top: 20px;
    height: 40px;
    border-radius: 20px;
}

.action-button {
    button {
        border: none;
        line-height: 26px;

        svg {
            font-size: 11px !important;
        }
    }

    .blue {
        background: #273896;
    }

    .green {
        background: #20A74E;
    }

    .pink {
        background: #C71C8E;
    }

    .red {
        .anticon svg{
            color: white !important;
        }
        background: #ff0000;
        color: white ;
    }
}

.refresh-button {
    height: 41px;
    width: 37px;
    border-radius: 10px;
    background-color: #fff !important;
    border: none;
    margin-right: 20px;

    svg {
        font-size: 18px;
    }
}

.bre-status-box {
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    // max-height: 290px !important;
    // // overflow: auto;
    // min-height: 230px !important;

    // @media (max-width: 1000px) {}

    margin-bottom: 17px;
}
.multi-lender-offer {
    --textColor:#4285F4;
    border-top: 1px solid #ccc;
    padding: 10px;
    color: var(--textColor);
    display: flex;
    justify-content: center;
    font-weight: 400;
    span {
        display: flex;
        align-items: center;
        img {
            margin: 5px;
        }
        cursor: pointer;
    }
}

.lead-detail-box {
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    height: 100%;

    .heading {
        display: flex;
        border-bottom: solid 1px #ccc;
        padding: 6px 15px;
        justify-content: space-between;
        min-height: 46px;

        h5 {
            font-weight: 900;
            font-size: 17px;
            line-height: 22px;
            color: #000;
            margin-bottom: 0px;
        }

        button {
            font-size: 12px;
        }
    }

    .custom-row {
        justify-content: space-between;
        margin-bottom: 10px;

        .gap {
            gap: 4px !important;
        }
    }

    .title {
        color: #9f9f9f;
        font-size: 13px;
    }

    .border-top {
        border-bottom: solid 1px #e9e9e9;
    }

    .lead-details {
        padding: 10px 15px;
        border-bottom: solid 1px #e9e9e9;

        .source {
            font-weight: 400;
            font-size: 12px;
            color: #6a6767;
        }

        h5 {
            margin-bottom: 2px;
        }
    }

    .details-item {
        padding: 15px;

        textarea {
            width: 100%;
            border-radius: 8px;
        }

        .btn-space {
            display: flex;
            margin-top: 10px;
            justify-content: end;
            clear: both;
        }
    }
}

.timeline::-webkit-scrollbar {
    width: 8px;
    background-color: #F5F5F5;
    display: block;
}

.timeline::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: lightgray;
}

.timeline {
    --color:#C71C8E;
    padding: 27px 15px;
    min-height: 400px;
    max-height: 700px;
    overflow: hidden;
    overflow-y: auto;
    //-ms-overflow-style: none;
    /* Internet Explorer 10+ */
    //scrollbar-width: none;

    /* Firefox */
    .timeline-box {
        .ant-timeline-item-head{
            background-color:var(--color);
        }
        h4 {
            margin-bottom: 0px;
            color: var(--color);
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 15px;

            .date {
                font-weight: 400;
                font-size: 11px;
                color: #a4a4a4;
            }
        }

        h5 {
            margin: 0px;
        }

        .title-date {
            span.date {
                font-weight: 400;
                font-size: 11px;
                color: #a4a4a4;
            }
        }
    }
}

.close-case-box {
    padding: 10px 0px;
}

.menu-list {
    --bgColor: #4285F430; 
    --color:#4285F4;
    .ant-dropdown-trigger {
        cursor: pointer;
    }
  .ant-dropdown-menu-item.ant-dropdown-menu-item-selected.ant-dropdown-menu-item-only-child{
        background-color: var(--bgColor);
        color: var(--color);
    }
}

ul.menu-list {
    width: 200px;
    border-radius: 8px;
}

// .top-view {
//     .view {
//         height: 100px;
//         border-radius: 8px;
//         padding-block: 8px;
//         padding-inline: 16px;
//         display: grid;

//         .ant-typography {
//             color: white;
//         }

//         .first-char {
//             background-color: white;
//             opacity: 0.6;
//             display: flex;
//             width: 25px;
//             height: 25px;
//             border-radius: 50%;

//             .ant-typography {
//                 color: #1D91AE;
//                 margin: auto;
//             }
//         }
//     }

//     .loan-type {
//         background: linear-gradient(#3890F5, #39B3F6);
//     }

//     .lead-stage {
//         background: linear-gradient(#0296BC, #26E3FC);
//     }
// }
.top-view {
    height: 60px;
    border-radius: 8px;
    padding-block: 5px;
    padding-inline: 10px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 10px;
    background: #ffffff;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
}

.field-pan {
    padding: 4px 10px !important;
}

.success-bureau {
    height: 60px;
    border-radius: 12px;
    border: 1px solid #20a74e;
    color: #20A74E;
}

.bureau-color {
    border: 1px solid red;
    color: red;

}

.consent-bureau {
    border: 1px solid #273896;
    color: #273896;
}

.request-bureau {
    --bgColor:#273896;
    height: 65px;
    border-radius: 12px;
    border: 1px solid var(--bgColor);
    color: var(--bgColor);
}

.retry-btn {
    float: right;
    width: 60px;
    border-radius: 15px;
}

.modal-btn {
    width: 130px;
    margin-bottom: 20px;
}

.refresh-btn {
    height: 50px;
    width: 100px;
    border-radius: 30px;
    background-color: #273896;
    border: none;

    svg {
        font-size: 12px;
    }
}

.ant-btn-primary:focus,
.ant-btn-primary:hover {
    background-color: #e9e9e9;
    // border-color: #2e43b2;
    color: black;
}

.tab-3 {
    .ant-tabs-nav {
        .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
                .ant-tabs-tab {
                    width: 33% !important;
                }
            }
        }
    }
}

.selected-row {
    background-color:   rgba(225, 33, 33, 0.1);

  }

  .leadlist-title{
    color:#bdbdbd;
  }

  .text-right {
    text-align: right;
  }

  .title-space{
    padding-top: 15px;
  }
  .paginationview{
    margin-bottom: 5%;
  }