.disbursed-lenders-header{
    margin-bottom: 10px;
    .disbursed-heading{
        font-size: 12px;
    }
}
.selected-lender-row{
    align-items: center;
    .disbursed-row{
        h5{
            font-size: 13px;
            font-weight: normal;
        }
    }
}

.btn-add {
    --bgColor:#C71C8E;
    border: none;
    background: none;
    padding: 0px;
    svg {
        padding: 4px;
        margin-bottom: 10px;
        path{
            fill:  var(--bgColor) !important;
        }
        border-radius: 20px;
    }
}

.inline-label{
    .ant-row{
        display: block;
        .ant-col{
            width: 100%;
            label{
                display: block;
                text-align: left;
                height: auto;
            }
        }
    }
}

.payout-collapse{
    background-color: #fff;
    border: solid 1px #f6f6f6;
    border-radius: 6px;
    box-shadow: 0px 1px 5px #e1e1e1;
    .ant-collapse-item{
        border-bottom: none;
        .ant-collapse-header{
            width: 100%;
            min-height: 44px;
            // padding: 4px 8px;
            // .ant-collapse-expand-icon{
            //     display: none;
            // }
            .ant-collapse-header-text{
                width: 100%;
                h4{
                    font-size: 14px;
                    line-height: normal;
                    margin-bottom: 0px;
                }
                h6{
                    font-size: 13px;
                    margin-bottom: 0px;
                }
                .api-intergrated-text{
                    --appSecondary: #8c2e8b;
                    display: block;
                    font-size: 14px;
                    font-weight: 500;
                    color: var(--appSecondary);
                    line-height: 15px;
                    padding-left: 6px;
                    img{
                        margin-right: 5px;
                    }
                    circle {
                        stroke: var(--appSecondary);
                    }
                    path {
                        fill: var(--appSecondary);
                    }
                }
            }
        }
        .ant-collapse-content-box{
            padding: 0px;
            padding-bottom: 16px;
        }
    }
}

.bg-title {
    padding-block: 10px;
    padding-inline: 16px;
    background: rgba(217, 217, 217, 0.2);
}

.leadDisburse-Detail-scroll{
   max-height: 200px;
   overflow: hidden;
   overflow-y: auto;
}
.leadDisburse-Detail-scroll::-webkit-scrollbar {
    width: 8px;
    background-color: #F5F5F5;
    display: block;
    height:8px;
}

.leadDisburse-Detail-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: lightgray;
    
}

