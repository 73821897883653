.boost-wrapper {
  text-align: center;
  margin-bottom: 20px;
}

.boost-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.boost-container {
  background: linear-gradient(270deg, #ffe5fc 20%, #fff 65%),
    linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.1) 100%
    );
  border-radius: 16px;
  border: 1px solid #cdcce8;
  padding: 0;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  box-shadow: 0px 4px 10px 0px #fa12e340;
}

.boost-content {
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  align-items: center;

}

.boost-left {
  flex: 0.9;
  text-align: left;
  padding: 0;
  margin: 0;
  padding-right: 20px;
  border-right: 1px solid rgba(123, 121, 249, 0.3);

  // @media (max-width: 600px) {
  //   border-right: none;
  //   border-bottom: 1px solid rgba(123, 121, 249, 0.3);
  // }

  h2 {
    line-height: 10px;
    font-size: 20px;
    margin: 10px 0;

    span {
      line-height: 40px;
    }
  }

  .boost-left-container {
    padding: 50px;
  }
}

.one-platform {
  letter-spacing: 0.25rem;
  font-weight: bold;
  color: #0f467c;
  font-size: larger;
}

.banner-text {
  span {
    line-height: 1.5;

    font-size: 40px;
    font-weight: bold;
  }
}

.highlight {
  color: #ff007a;
}

.highlight-blue {
  color: #0f467c;
}

.boost-button {
  background: #fff1fe;
  color: #ff007a;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  border: none;
  font-weight: 700;
}

.boost-right {
  flex: 1.1;
  text-align: left;
  padding: 0;
  margin: 0;

  ul {
    list-style-type: disc;
    padding: 0;
    margin-bottom: 0px;

    li {
      border-bottom: 1px solid rgba(123, 121, 249, 0.3);
      font-size: large;
      padding: 20px 10px;
      list-style-position: inside;
      color: #0f467c;
      display: flex;
      gap: 10px;
      .dot {
        height: 6px;
        width: 7px;
        background-color: #0F467C;
        border-radius: 10px;
        margin-top: 10px;
      }
      &:last-child {
        border-bottom: none;
      }
      &::marker {
        display: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .boost-title{
    width: 250px;
    display:inline-block
  }
  .one-platform{
    text-align: left;
  }
  .boost-container {
    border: none;//1px solid #cdcce8;
    max-width: 375px;
    width: 100%;
  }
  .boost-content{
    // width: 325px;
    flex-direction: column;
  }
  .boost-left {
    margin-top: -20px;
    margin-left: -100px;
    border:none
  }
  .banner-text span{
    font-size: 30px;
  }
  .boost-left h2 span{
    line-height: 30px;
  }
  .boost-button{
    font-size: 12px;
    padding: 5px;
  }
  .boost-right {
    flex: 1.1;
    text-align: left;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    width: 90%;
    .boost-left-container {
      padding: 50px;
      padding-top: 70px;
    }
  
    ul {
      // width: 250px;
      border: 1px solid rgba(123, 121, 249, 0.3);
      border-radius: 16px;
        
  
      li {
        // width: 250px;
        border-bottom: 1px solid rgba(123, 121, 249, 0.3);
        font-size: large;
        padding: 20px 10px;
        list-style-position: inside;
        color: #0f467c;
        &:last-child {
          border-bottom: none;
        }
      }
    }
    .mobileView{
      margin-left:25px;
      font-size: inherit;
    }
  }
  }