.white {
  width: 1093px;
  height: auto;
  padding: 0px 110px 0px 110px;
  gap: 0px;
  border-radius: 25px;
  background: rgba(255, 255, 255, 1) !important;
  box-shadow: 0px 4px 100.67px 0px #193a4b33;
  margin: 20px;
  margin-left: 200px;
  padding-top: 2%;
  padding-bottom: 2%;

}


.pagePadding {
  padding: 25px 0px;
}

.contactRightPnl {
  width: 326px;
  padding-right: 10px;
  height: 520px;
  overflow-y: auto;
  overflow-x: hidden;
}

.contact-form {
  max-width: 301px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.sectionTitle {
  font-size: 16px;
  padding-bottom: 10px;
}

.captcha-style {
  height: 76px;
  width: 274px;
  padding: 15px 0px;
}

.button-style {
  width: 304px;
  height: 33px;
  border-radius: 10px;
  background: linear-gradient(90deg, #0f467c 0%, #ed217c 100%);
}
.image-style {
  width: 517px;
  height: 452px;
}

@media (max-width: 768px) {
  .ant-col-xs-0 {
    display: block !important;
  }
  .white {
    width: 81%;
    // height: 770px;
    padding: 0px 10px 0px 10px;
    margin-left: 0px;
    margin-bottom: 20px;
    margin-inline: 15px;
    margin-inline: auto;
  }
  .contact-form {
    max-width: 306px;
  }

  .image-style {
    display: none;
  }
  .contactRight {
    margin-top: 0px;
    position: absolute;
  }
  .contact-form input,
  .contact-form textarea {
    width: 98%;
    padding: 10px;
  }
  .button-style {
    width: 300px;
  }
  .rc-anchor .rc-anchor-normal {
    width: 290px;
  }
}

@media (max-width: 375px) {
  .white {
    width: 94%;
  }
  .contact-form {
    max-width: 307px;
  }
}
