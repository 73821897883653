.header-container {
  .container .mobile-menu-button {
    display: none;
    .btn-book-now {
      border-color: #0f467c;
      color: #0f467c;
      border-radius: 10px;
    }
  }
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 10px;
  text-align: center;
  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
  }
  .first-column {
    margin-left: 43px;
    margin-right: 49px;
    height: 35px;

    border: 0.5px solid #ceceea;
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;

    justify-content: center;
    align-items: center;
    .logo-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      padding-right: 5px;
      padding-left: 5px;
      gap: 4px;

      .divider {
        width: 1px;
        height: 35px;
        background-color: black;
        margin-inline: 6px;
      }

      .filter-text {
        text-align: left;
        width: 80px;
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: 0;
      }
    }
  }
  .second-column {
    margin-right: 77px;
    height: 35px;
    border: 0.7px solid #ceceea;
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;

    justify-content: center;
    align-items: center;

    .text-container {
      font-size: 14px;
      padding: 4px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      .ant-btn {
        border: none;
        border-radius: 10px;
        &.btn-selected {
          background: linear-gradient(90deg, #0F467C 0%, #ED217C 100%);
          color: white;
        }
      }
      .ant-typography {
        display: block;
        margin: 0;
      }
    }
  }
  .third-column {
    padding: 10px;
    margin-left: 9px;
    margin-right: 168px;
    height: 35px;

    border: 0.3px solid #ceceea;
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;

    justify-content: center;
    align-items: center;
  }
  .whats-new {
    box-shadow: 0px 10px 25px 0px rgba(16, 30, 54, 0.15);
    padding-bottom: 10x;
  }
}
.whats-new {
  padding-top: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.custom-dropdown-menu {
  border-radius: 10px;
  box-shadow: 0px 4px 34px 0px #00000040;

  .ant-dropdown-menu-item-active {
    background: linear-gradient(90deg, #23437c 0%, #d7257c 100%);
    color: white;
  }
}
@media screen and (max-width: 768px) {
  .header-container .container .second-column {
    display: none;
  }
  .header-container .container .third-column {
    display: none;
  }
  .header-container .first-column .logo-container .divider {
    display: none;
  }
  .header-container .first-column .logo-container .filter-text {
    display: none;
  }
  .header-container .first-column {
    padding: 2px;
  }
  .whats-new {
    display: none;
  }
  .header-container {
    padding-inline: 10px;
  }
  .second-column .text-container {
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
  }
  .first-column .logo-container {
    gap: 10px;
    text-align: center;
  }
  .whats-new {
    padding-top: 10px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 767px) {
  .lending-collapse {
    border: none;
    margin-right: -20px;
    .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text{
        padding-left:14px;
    }
    .ant-collapse-content > .ant-collapse-content-box{
        padding: 26px;
    }
    .ant-collapse-icon-position-end
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-arrow {
      position: absolute;
      top: 40%;
      right: 0px;
      left: auto;
      margin: 0;
      transform: translateY(-50%);
    }
    .ant-collapse {
      border: none !important;
    }
    .ant-collapse .ant-collapse-item {
      border: none !important;
      box-shadow: none !important;
    }
    .ant-collapse-header {
      border: none !important;
      background-color: white !important;
      font-weight: normal !important;
      padding: 0 !important;
      color: white;
    }
    .ant-collapse-content {
      border: none;
    }
  }

  .ant-drawer-header {
    display: none;
  }
  .drawer-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 18px;
    .menu-row {
      display: flex;
      justify-content: flex-start;
      gap: 20px;
      .btn-selected {
        background: linear-gradient(90deg, #0F467C 0%, #ED217C 100%);
        color: white;
        border-radius: 8px;
      }
    }
    .menu-row a {
      text-decoration: none;
      color: black;
      display: block;
      width: 100%;
    }
  }
  .ant-drawer-close {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  .header-container .container .mobile-menu-button {
    display: table-row-group;
    padding-top: 6px;
  }
  .header-container .first-column {
    .logo-container .divider {
      height: 47px;
    }
    margin-left: 0px;
    padding-right: 7px;
    margin-right: 0px;
  }

  .container {
    //display:
    justify-content: space-between;
  }
}
