@import 'shared/style/Variables.scss';
@import 'shared/style/Mixins.scss';

.moneyWideTemplate {
  padding: 0;
  // min-height: 70%;
  // margin: 10px 0 0 0 !important;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // background: #E5E5E5;
  // .login-wrapper{
  //   min-width: 80%;
  //   max-width: 400px;
  // }
  .logo{
    width: 122px;
    margin: auto;
    margin-bottom: 20px;
    img{
      width: 100%;
    }
  }
  .login-form-wrapper {
    width: 100%;
    height: 100%;
    @include box-shadow(0px 4px 22px rgba(0, 0, 0, 0.1));
    .form-wrapper{
      text-align: left;
    }
  }
  .ant-input-password{
    padding-right: 15px;
  }
  // input:-webkit-autofill,
  // input:-webkit-autofill:hover,
  // input:-webkit-autofill:focus,
  // input:-webkit-autofill:active {
  //     transition: background-color 500s ease-in-out 0s;
  //     -webkit-text-fill-color: rgb(0, 0, 0) !important;
  // }
  input, .ant-input-affix-wrapper > input.ant-input{
    border-radius: 15px;
    padding: 4px 11px;
  }
  .custom-input-phone .ant-input-wrapper .ant-input-group-addon .ant-select,
  .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 15px 0px  0px 15px;
  }
  .custom-app-card {
   margin-top: 10rem;   
  }
  .ant-card-body {
    padding: 10px;
  }
  .SubmitButton {
    margin: 10px 10px 15px 0px;
  }
  .ant-btn-default {
    background-color: #008000;
    border-color: #008000;
    color: #fff;
  }
   .ant-btn[disabled] {
    background-color: #008000;
    border-color: #008000;
    color: #fff;
    opacity: 0.5;
    
  }
  .lastScreen {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    & .mainLabel {
      font-size: 3rem;
      margin: auto;
      color: #008000;
    }
    & .subLabel {
      margin: auto;
      font-size: 1.5rem;
      color: #008000;
    }
  }
  .ant-btn-primary:focus, .ant-btn-primary:hover {

    background-color: #e9e9e9;
   // border-color: #2e43b2;
    color: black;
}
.rightTickIcon{ 
  width: 100px;
  margin: auto;
}
.ant-checkbox-wrapper {
  display: flex;
  justify-content: center;
  font-size: 0.8rem;  
}
.ifameClass {
  min-height: 100vh;
  height: 100vh;
}
.bottomSection{
  position: absolute;
  bottom: 0px;
  background-color: white;
  width: 100%;
  padding: 5px;  
}
}

