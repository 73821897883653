* {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
}

.heading {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 19px;
    line-height: 40px;
    size: 26px;
}

.heading2 {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 5%;
}

.main {
    justify-content: center;
    padding-top: 3%;
    text-align: center;
}

.mobileText {
    margin-left: -70%;
    /* opacity: 0.7; */
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* identical to box height, or 15px */
    font-feature-settings: 'calt' off;
    /* Body Text/Body Color */
    color: #212529;
    opacity: 0.4;
}

.showul{
    list-style: none;
    padding: 0px;
    text-align: left;
}
.imgwid
{
    width: 70%;
}
.spanmar
{
    color: #939292;
    margin-left: 2%;
    font-size: 13px;
}
.g-recaptcha {margin: 0 auto;display: table;}

.maincard {
    border-radius: 20px;
    max-width: 400px;
    min-height: 300px;
    display: block;
    margin: 20px auto;
    padding: 10px;
}

.eclips4 {
    margin-bottom: 20px;
    margin-left: 10px;
}

.vector {
    position: relative;
    /* right: 120%; */
    margin-left: -300%;
    margin-top: -20px;
}

.formbutton {
    width: 100%;
    border-radius: 10px;
    background-color: #273896;
    color: #fff;
    padding: 2%;
    height: 3%;
}

.fieldgradient {
    /* border: 2px solid #c71c8e; */
    background: linear-gradient(#fff, #fff) padding-box,
        linear-gradient(90deg, #273896, #c71c8e) border-box;
    border-width: 3px;
    border-style: solid;
    border-radius: 10px;
    border-color: transparent;
    padding: 1%;
    margin: 20px 0;
    border-image-slice: 1;
}

.top {
    position: fixed;
    left: 0;
    bottom: 15%;
    opacity: 0.3;
}

.bottom {
    position: fixed;
    left: 0;
    bottom: 23%;
    opacity: 0.3;
}

.union {
    position: fixed;
    right: 5%;
    bottom: 0;
    opacity: 0.3;
}

/* set password page design */
.inputField {
    border: 1px solid #efefef;
    box-shadow: 1px 5px #efefef;
    border-radius: 5px;
    line-height: 40px;
}

.checkbox {
    background: #c4c4c4;
    opacity: 0.4;
    border: 1px solid #000000;
    border-radius: 3px;
    /* border: none; */
}

/* otp verification */
.otpBorder {
    outline: none;
    /* border-image: linear-gradient(90deg, #273896, #c71c8e) 1; */
    background: linear-gradient(#fff, #fff) padding-box,
        linear-gradient(90deg, #273896, #c71c8e) border-box;
    border-width: 5px;
    border-style: solid;
    border-radius: 10px;
    border-color: transparent;
    padding: 1%;
    margin: 20px 0;
    border-image-slice: 1;
}

.otpBox {
    min-width: 50px;
    height: 50px;
    margin-right: 8px;
    border-radius: 10px;
    padding: 0;
    font-size: 30px;
}

.otpContainer {
    margin-left: 2%;
}

@media (max-width: 460px) {
    .otpBox {
        min-width: 40px;
        height: 40px;
        margin-right: 5px;
        font-size: 20px;
    }
}

@media (max-width: 360px) {
    .otpBox {
        min-width: 30px;
        height: 30px;
        margin-right: 2px;
        font-size: 20px;
    }

    .otpContainer {
        margin-left: 2%;
    }
}
