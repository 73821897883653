.field-bg-border{
    padding-block: 4px;
    padding-inline: 10px;
    border-radius: 12px;
    margin-bottom: 10px;
    background: transparent;
    box-shadow: none;
    border: solid 1px lightgray;
    .ant-form-item-label{
        label {
            height: auto !important;
        }
    }
    .ant-select-selector,
    .custom-input-phone,
    .custom-input-number,
    .custom-input{
        background: transparent !important;
        border-radius: 0px !important;
        padding: 0px !important;
        padding-left: 0px !important;
        border: none !important;
        font-size: 16px !important;        
    }
    .ant-form-item-explain-error{
        padding-bottom: 8px;
    }
    &.bg-white {
        background: white;
        border: none;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
    }
    &.bg-disabled {
        background: #DADADABF;
        border: none;
    }
}

.Added-Product-List {
    box-shadow: 5px 5px 13px rgba(223, 224, 230, 0.5);
    max-height: 500px;
}