.scorecard-tabs {
    margin-top: 25px;
    --bgColor: #273896;
    .ant-tabs-tab {
        padding-inline: 30px !important;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
        background: var(--bgColor);
        border-radius: 8px 8px 0 0 !important;

        .tabActive {
            color: white !important;
            font-weight: 350 !important;
        }
    }
}

.content-sb {
    display: flex;
    justify-content: space-between;
  }

  .full-details{
    padding-left: 108px;
    gap: 50px !important;
  }

  .publish-table-column-width {

      .ant-table thead>tr>th,
      .ant-table tbody>tr>td,
      .ant-table tfoot>tr>th,
      .ant-table tfoot>tr>td {
          white-space: unset;
      }
  }