
.dashboard-menus {
  //margin: 40px 0px;
  display: table;
  width: 100%;

  .outer {
    list-style: none;
    margin: 0px;
    padding: 0px;

    .inner {
      width: 110px;
      height: 110px;
      margin-bottom: 10px;
      border-radius: 10px;
      float: left;
      margin-right: 20px;

      a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 10px;
        padding-top: 25px;
        position: relative;

        svg {
          font-size: 14px;
          margin-top: 3px;
        }

        #header-shape-gradient {
          --color-stop: #f12c06;
          --color-bot: #faed34;
        }

        span.count {
          position: absolute;
          right: -6px;
          top: 15px;
          color: white;
          background: #8c2e8b;
          width: 20px;
          // padding-inline: -1px;
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          text-align: center;
          border-radius: 50px;
          -webkit-text-fill-color: unset;
        }

        span {
          font-size: 20px;
          display: block;
          font-size: 14px;

          background: -webkit-linear-gradient(#042750, #c71c8e);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}