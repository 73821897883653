.field-bg {
  padding: 8px 10px;
  background: #FFFFFF;
  box-shadow: 5px 5px 13px rgba(223, 224, 230, 0.5);
  border-radius: 12px;
  //border: solid 1px #f7f7f7;
  margin-bottom: 10px;

  .ant-select-selector,
  .custom-input-phone,
  .custom-input-number,
  .custom-input ,
  .custom-textarea{
    border-radius: 0px;
    padding: 0px !important;
    padding-left: 0px;
    border: none !important;
    font-size: 16px;
      outline: none;
  } 
}

.loan-type {
  .item {
    margin-bottom: 23px;
    width: 100%;
    padding-block: 10px;
    padding-inline: 16px;
    border-radius: 8px;
    background: linear-gradient(#3890F5, #39B3F6);

    .ant-space-item {
      line-height: normal;

      .ant-typography {
        margin-bottom: 0px;
        line-height: normal;
        color: white;
      }
    }
  }
}

.col-border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}

.form-box {
  padding: 0px !important;
  padding-bottom: 20px !important;
}

.btns {
  // width: 100%;
  display: flex;
  justify-content: flex-end;
  .getConsent {
    background-color: #3890F5;
    border: none;
    font-weight: normal;
  }
  button {
    min-width: 10rem;
    height: 2.5rem;
    border-radius: 10px;
  }
  .consentLink {
    font-weight: normal;
    color: #C71C8E;
    padding: 10px;
  }
  .consentSuccessLink {
    color: green;
    padding: 5px;
    font-size: 1rem;
    img {
      padding: 5px;
    }
  }
}
// .custom-radio .ant-space-align-center .ant-space-item .ant-radio-button-wrapper-checked {
//  --borderColor:#C71C8E;
//   background-color:var(--borderColor);
  
// }


.form-group {
  // box-shadow:  1px 1px 9px 1px  grey;
  // border-radius: 12px;
  // margin: 12px;
  // .ant-row-top{
  //   flex-direction: column;
  // }
  .dropdown {
    height: 36.42px;
    // padding-top: 7px;
  }
  .field-string {
    display: flex;
    flex-direction: column;
    padding: 8px 10px;
    background: #FFFFFF;
    box-shadow: 5px 5px 13px rgba(223, 224, 230, 0.5);
    border-radius: 12px;
    border: solid 1px #f7f7f7;
    margin-bottom: 10px;

    .form-control {
      border-radius: 11px !important;
      padding: 8px;
      border: solid 1px #dadada;
      // border: none;
      // outline: none;
    }
  }

  .btn-add {
    height: 30px;
    width: 25px;
    background-color: green;
    border-radius: 5px;
    flex: 0 !important;
    padding: 15px !important;
    // background-image: url("https://via.placeholder.com/20") ;
    background-image: url("../../../../assets/images/add.svg") !important;
    background-repeat: no-repeat !important;
    background-position: center;
    background-size: 14px !important;
  }

  .btn-group {
    // display: flex; 
    justify-content: center !important;
  }

  .array-item-remove {
    height: 30px;
    width: 25px;
    border-radius: 5px;
    flex: 0 !important;
    padding: 15px !important;
    background-color: white;
    background-image: url("../../../../assets/images/delete.svg") !important;
    background-repeat: no-repeat !important;
    background-position: center;
    background-size: 18px !important;
  }

  .array-item-move-up {
    height: 30px;
    width: 25px;
    border-radius: 5px;
    flex: 0 !important;
    padding: 15px !important;
    background-color: white;
    background-image: url("../../../../assets/images/arrow_up.svg") !important;
    background-repeat: no-repeat !important;
    background-position: center;
    background-size: 20px !important;
  }

  .array-item-move-down {
    height: 30px;
    width: 25px;
    border-radius: 5px;
    background-color: white;
    flex: 0 !important;
    padding: 15px !important;
    background-image: url("../../../../assets/images/arrow_down.svg") !important;
    background-repeat: no-repeat !important;
    background-position: center;
    background-size: 20px !important;
  }
}

.col-md-6 {
  display: flex;
  margin-top: 5px;
}
.regenrate-otp{
  background: transparent !important;
  border: none;
  color: #273896;
  margin-top: -3px;
  :hover{
    color: #5798e9fc;
  }
}

.loader-view-flowable {
  position: absolute;
  inset: 0;
  z-index: 1001;
  background-color: black;
  opacity: 0.7;
  display: grid;
  align-content: center;
  .ant-spin {
    .ant-spin-text {
      font-size: 20px;
      margin-top: 10px;
    }
  } 
}
.consentSection {
  padding: 5px;
  & .consentLink {
    text-decoration: underline;
    cursor: pointer;
    margin: 5px;
  }
  & .consentLink:hover {
    text-decoration: underline;
    cursor: pointer;
    color: #273896;
  }
}
.formio-form {
  & .page-item {
    button {
      display: none;
    }
  }
}
.formio-form {
  .pagination {
    & .page-item {
      button {
        display: block;
      }
    }
  }
}

.formio-component-checkbox {
  & .checkbox {
    margin: 10px 0px 12px 0px;
  }
}
.formio-component-button  {
  &.formio-component-submit {
    display: flex;
      justify-content: end;
      margin: 5px;
  }
}
.ant-modal-content {
  border-radius: 20px !important;
  overflow: auto;

}
.multiplender-offer-main {
  padding: 10px 8px;
  & .ant-table-content {
    table{
      table-layout :auto !important
    }
  }
  th {
    font-size: 15px !important;
  }
  td {
    font-size: 16px;
  }
  .multiplender-offer {
    padding-block: 10px;
    border-bottom: 1px solid #bfc4e1;
  }
  
}
.anticon svg {
  display: inline-block;
  margin-bottom: 22% !important;
} 