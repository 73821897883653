.square {
  width: 30px;
  height: 45px;
  border-radius: 12px;
  border: 3px solid #fff;
  padding: 0 1rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  box-shadow: 1px 1px 9px 1px grey;
}
.square:hover {
  border: 3px solid #c71c8e;
}
