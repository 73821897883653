.lead-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    height: 91vh;

    .lead-container {
        display: inline-block;
        justify-content: center;
        align-items: center;
        background-color: #fff; // Light background for container
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 8px;
        height: fit-content;
        margin-top: 10px;

        .lead-items {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
            gap: 20px; // Space between items
            width: 100%;
            max-width: 900px; // Adjust max width as needed
            border-radius: 8px;
            padding: 10px;

            .lead-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                padding: 10px;
                position: relative;
                border-right: 1px solid #ddd;
                cursor: pointer;

                &:first-child {
                    border-left: none;
                }

                &:last-child {
                    border-right: none;
                }

                .icon-style {
                    fill: #1890ff; // Blue icon color
                    margin-bottom: 10px;
                }

                .lead-label {
                    font-size: 14px;
                    font-weight: 500;
                    color: #333;
                    cursor: pointer;
                    margin-top: 10px;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .lead-wrapper {
        padding: 10px;
        height: auto; // Adjust height for mobile view

        .lead-container {
            padding: 10px;

            .lead-items {
                gap: 10px; // Reduce space between items for mobile view
                grid-template-columns: 1fr; // Single column layout for smaller screens

                .lead-item {
                    padding: 10px;
                    border-left: none; // Remove left border for mobile view
                    border-right: none; // Remove right border for mobile view
                    border-bottom: 1px solid #ddd; // Add bottom border for separation
                    min-width: 200px;

                    &:last-child {
                        border-bottom: none; // Remove bottom border for last item
                    }

                    .lead-label {
                        font-size: 12px; // Smaller font size for mobile view
                    }
                }
            }
        }
    }
}