.mobile-view-container {
    border-radius: 20px;
    height: auto;
    width: 100%;
}

.mobile-view-list {

    height: auto;
    width: 100%;
    padding: 10px;
}

.mobile-upload-container {

    margin-top: 20px;
    border-radius: 20px;
    height: auto;
    width: 100%;
}

.mobile-upload-document {
    padding: 30px;
}

.upload-area {
    // .ant-radio .ant-radio-checked {
    //     color: #C71C8E !important;
    // }
}

.custom-checkable {
    padding: 4px 10px;
     border: 1px solid rgba(39, 56, 150, 0.26);
    border-radius: 40px;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    margin-left: 0px;
    margin-top: 5px;
    &:hover,
    &:focus{
      color: rgba(0, 0, 0, 0.85) !important;
      background: none;
    }
}

.custom-checkable.ant-tag-checkable-checked {
    --bgColor:#273896;
    background: var(--bgColor);
    color: #fff !important;
  }

.types-document {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
}

.doc-list-mobile {
    display: table;
    width: 90%;
    padding-bottom: 30px;
    height: 215px;
    overflow: scroll;
    margin-top: 20px;

    ul {
        list-style: none;
        padding: 0px;
        margin: 0px;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: solid 1px #ededed;
            margin-bottom: 10px;
            padding-bottom: 10px;

            .doc-details {
                --fileIconColor:#273896;
                display: flex;
                align-items: center;

                svg {
                    font-size: 32px;             
                    margin-right: 5px;
                    path{
                        stroke: var(--fileIconColor);
                     }
                }

                span {
                    width: 170px;
                    font-size: 13px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    margin-left: 5px;

                    .type {
                        font-size: 12px;
                    }
                }
            }

            .doc-action {
                span {}

                span.remove {
                    color: #E12121;
                    cursor: pointer;

                    svg {
                        margin-right: 8px;
                        position: relative;
                        top: 3px;
                    }
                }
            }
        }

        li:last-child {
            border-bottom: none;
        }
    }
}

.upload-wrapper {
    .ant-upload-select {
        width: 100%;
        height: 100px;
        margin: 14px 0px;

        img {
            margin-right: 10px;
            height: 30px;
            width: 25px;
        }

        span {
            display: flex;
            width: 100%;
            height: 100%;
            text-align: center;
            align-items: center;
            justify-content: center;
            color: rgba(80, 80, 80, 1);
            font-size: 16px;
            border: 1px dashed  rgba(80, 80, 80, 1) !important;
            background: rgba(255, 255, 255, 1);
            cursor: pointer;
        }
    }

    .ant-upload-list {
        padding-bottom: 15px;

        .ant-upload-list-item {
            margin-bottom: 10px;
        }
    }
}


@media only screen and (max-width: 768px) {

.details-box-sharelink {
        .ant-collapse-content-box {
            text-align: left;
        }

        .doc-mobile-items-header {
            text-align: left;
    }
                                                                                                                                }
}
.lead-detail-box-mobile {
    height: 100%;

    .heading {
        display: flex;
        border-bottom: solid 1px #ccc;
        padding: 6px 15px;
        align-items: baseline;
        justify-content: space-between;
        min-height: 46px;
        align-items: center;

        h5 {
            font-weight: 900;
            font-size: 17px;
            line-height: 22px;
            color: #000;
            margin-bottom: 0px;
        }

        button {
            font-size: 12px;
        }
    }

    .custom-row {
        justify-content: space-between;
        margin-bottom: 10px;

        .gap {
            gap: 4px !important;
        }
    }

    .title {
        color: #9f9f9f;
        font-size: 13px;
    }

    .lead-details {
        padding: 10px 15px;
        border-bottom: solid 1px #e9e9e9;

        .source {
            font-weight: 400;
            font-size: 12px;
            color: #6a6767;
        }

        h5 {
            margin-bottom: 2px;
        }
    }

    .details-item {
        padding: 15px;

        textarea {
            width: 100%;
            border-radius: 8px;
        }

        .btn-space {
            display: flex;
            margin-top: 10px;
            justify-content: end;
            clear: both;
        }
    }
}

// .body{
//     background-color: #E5E5E5;
// }

.lead-deatils-text {
    // font-size: "10px";
}

.lead-deatils-text-highlight{
    font-size: "30px";
}
.header-upload {
    background-color: #fff;
    margin-bottom: 2rem;
    padding: 0.5rem;
}

.header-upload-text {
    font-weight: 300;
    font-size: 1.8em;
    line-height: 2em;
}


.details-title-sharelink {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    padding-inline: 10px;

    span {
        font-weight: normal;
        font-size: 1.4em;
    }

    .btn-view-more {
        border-color: #273896;
        color: #273896;

        span {
            font-size: 12px;
        }
    }
}

.details-box-sharelink {
    margin: 10px;
    padding: 16px;
    padding-top: 10px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);


    .keyValue {
        color: #525256;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #A3A3A3;
        padding-top: 10px;
        padding-bottom: 4px;
        align-items: center;

        span {
            font-size: 16px;
        }

        .span-value {
            font-weight: 600;
            padding-inline: 4px;
            border-radius: 2px;

            &.bg {
                background-color: #F4F5F7;
            }
        }

        &.hide-bottom {
            border-bottom: none;
        }
    }
}