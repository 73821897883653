.round-progress-bar-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .round-progress-bar {
    width: 130px;
    height: 130px;
  }
  
  .progress-bar-background {
    fill: none;
    stroke: #ddd; /* Light gray */
    stroke-width: 8;
  }
  
  .progress-bar {
    fill: none;
    stroke: #273896; /* Blue */
    stroke-width: 8;
    stroke-linecap: round;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
  
  .progress-text {
    font-size: 26px;
    font-weight: 400;
    text-anchor: middle;
    dominant-baseline: middle;
    fill: #333; /* Dark gray */
  }
  