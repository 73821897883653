.custome-devider-hozntl {
    min-height: 500px;
    height: auto;
}

.profession-btns {
    width: 100%;

    .ant-space {
        display: block;
        margin-top: 10px;

        .ant-space-item {
            width: 120px;
            float: left;
            margin-bottom: 10px;
            margin-right: 10px;

            .ant-radio-button-wrapper {
                display: block;
                text-align: center;
            }
        }
    }
}

.hide-selection .ant-table-selection-column {
    cursor: not-allowed;
    display: none !important;
}

.label-verficiation-pending {
    position: absolute;
    top: -50px;
    font-size: 11px !important;
    right: 0;
    color: #E12121;
    border: 1.5px solid #E12121;
    padding: 0px 2px;
    border-radius: 5px;
}

.label-verified {
    position: absolute;
    top: -50px;
    font-size: 13px !important;
    right: 0;
    color: #20A74E;
    padding: 0px 2px;

    svg {
        font-size: 12.5px !important;
        font-weight: bolder;
        margin-right: 2px;
        width: 13px !important;
        height: 15px !important;
    }
}
.label-verified-rejected {
    position: absolute;
    top: -50px;
    font-size: 11px !important;
    right: 0;
    color: #E12121;
    border: 1.5px solid #E12121;
    padding: 0px 2px;
    border-radius: 5px;

    svg {
        font-size: 11px !important;
        font-weight: bolder;
        margin-right: 2px;
        width: 10px !important;
        height: 10px !important;
    }
}



// view connector details


.connector-details-box {

    background: #fff;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    height: 100%;


}

.custom-row {
    justify-content: space-between;
    margin-bottom: 10px;

    .gap {
        gap: 4px !important;
    }
}

.title {
    font-weight: 400;
    font-size: 12px;
    color: #a4a4a4;
}

.connector-detail-box-inner{

    margin-top: "10px";
}

.textarea-comment {
    margin-bottom: 20px;
    textarea {
        border-radius: 10px;
    }
}