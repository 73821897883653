.add-button11{
    height: 40px;
    // width: 130px;
    width: 171px;
    border-radius: 10px;
    float: right;
    svg{
        margin-right: 10px;
        font-size: 13px;
    }
}