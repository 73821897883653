.searchBox{
    border-radius: 30px;
}

.doc-detail{
    --fileIconColor:#273896;
    display: flex;
    align-items: center;
    svg{
        font-size: 32px;
        margin-right: 5px;
        height: 35px;
        width: 35px;
        path{
            stroke: var(--fileIconColor);
         }
    }
    span{
        width: 170px;
        font-size: 13px;
        text-overflow: ellipsis;
        overflow: hidden; 
        white-space: nowrap;
        .type{
            font-size: 12px;
        }
    }
}
