.disbursal-main-div {
  margin-top: 15px;
  border-top: 1px solid rgb(235, 234, 238);
}

.fields-text {
  font-size: 12px;
}

.field-lable {
  font-size: 12px;
  .star {
    color: red;
    font-size: 10px;
  }
}

.lender-detail1-main-div {
  border-top: solid 3px #e9e9e9;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  gap: 6px;
}

.lender-textarea {
  border-radius: 10px;
  width: 100%;
  height: 38px !important;
}

.lenderId-div {
  display: flex;
  align-items: center;
  // margin-left: 12px;
}

.decision-item {
  .ant-form-item-label {
    label {
      font-size: 12px;
    }
  }
  .ant-form-item-explain-error,
  .ant-select-selection-item {
    font-size: 12px;
  }
  .ant-select-selection-search,
  .ant-picker-input,
  .ant-form-item-control-input-content,
  .ant-input-number-input-wrap {
    input {
      font-size: 12px;
    }
  }
}

.field-error {
  .ant-select-selector,
  .custom-input,
  .custom-input-number {
    border-color: red !important;
  }
}

.lenderName-decision {
  font-size: 12px;
   margin-top: 7px;
   max-width:100%;
   width:90%;
   word-break:break-word;
}